<template>
    <v-container fluid class="py-0 br-15">
        <Filtros
            @filtrar="filtrarResumo"
            @modalOpen="openModal"
            :hideFiltro="['fornecedor', 'importar']"
            @exportar="exportar"
        />
        <Modal
            v-model="modalAdd"
            :title="(itemUpdate == null ? 'Adicionar' : 'Atualizar') + ' orientação'"
            :fullscreen="false"
            maxWidth="800px"
        >
            <template slot="body" v-if="modalAdd">
                <!-- <BeneficiosAdd
					@modalClose="modalAdd = false"
					@filtrar="filtrarResumo"
					:fornecedor="fornecedor['add']"
					:ano="ano['add']"
					:itemUpdate="itemUpdate"
				/> -->
                <OrientacoesAdd
                    @modalClose="modalAdd = false"
                    @filtrar="filtrarResumo"
                    :ano="ano['add']"
                    :itemUpdate="itemUpdate"
                />
            </template>
        </Modal>
        <!-- <v-card class="mt-5 pa-2"> -->
        <v-row class="mt-3">
            <v-col cols="12" md="5" lg="5" xl="5">
                <OrientacoesArquivosVisualizar
                    :arquivos="viewData.arquivos"
                    :ano="viewData.ano"
                />
            </v-col>
            <v-col cols="12" md="7" lg="7" xl="7">
                <OrientacoesLista
                    :ano="ano['list']"
                    @modalOpen="openModal"
                    @editItem="editItem"
                    @visualizar="viewData = $event"
                />
            </v-col>
        </v-row>
        <!-- </v-card> -->
    </v-container>
</template>

<script>
import Modal from "./../../components/Modal.vue";
import Filtros from "./_components/Filtros.vue";
import OrientacoesAdd from "./_components/OrientacoesAdd.vue";
import OrientacoesLista from "./_components/OrientacoesLista.vue";
import OrientacoesArquivosVisualizar from "./_components/OrientacoesArquivosVisualizar.vue";

export default {
    components: {
        Filtros,
        Modal,
        OrientacoesAdd,
        OrientacoesLista,
        OrientacoesArquivosVisualizar,
    },
    data() {
        return {
            ano: {
                list: null,
                add: null,
            },
            modalAdd: false,
            itemUpdate: null,
            viewData: {},
        };
    },
    methods: {
        async exportar(filtros) {
            this.load = true;

            var response = await this.$http().post(
                "/simulacao/orientacoes/exportar",
                {
                    data: {
                        ano: filtros.ano,
                    },
                }
            );

            if ((response || { status: 500 }).status != 200) {
                this.$dialog.notify.error(
                    "Falha ao exportar o benefício, por favor, tente novamente.",
                    {
                        position: "top-right",
                        timeout: 5000,
                    }
                );
                this.load = false;
                return null;
            }

            window.location = this.$http("baseURL") + response.data.url;

            this.load = false;
        },
        setData(area, filtros) {
            this.ano[area] = null;
            this.itemUpdate = null;

            return new Promise((resolve) =>
                setTimeout(() => {
                    this.ano[area] = filtros.ano || 0;
                    resolve(true);
                }, 200)
            );
        },
        async filtrarResumo(filtros) {
            this.viewData = {};
            await this.setData("list", filtros);
            //console.log("Filtrar=>", filtros);
        },
        async openModal(filtros) {
            this.viewData = {};
            await this.setData("add", filtros);
            this.modalAdd = true;
        },
        async editItem(item) {
            this.viewData = {};
            this.itemUpdate = item;
            this.modalAdd = true;
        },
    },
};
</script>

<style></style>
