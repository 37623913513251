<template>
    <v-card class="pa-5 filtro-borda" elevation="0">
        <div>
            <v-form ref="formFiltro" @submit.prevent>
                <v-row>
                    <v-col
                        cols="12"
                        sm="12"
                        md="3"
                        lg="3"
                        xl="3"
                        v-if="!ckHideFiltro('fornecedor')"
                    >
                        <v-autocomplete
                            label="Fornecedor"
                            :return-object="true"
                            class="input-cinza"
                            v-model="filtros.fornecedor"
                            :items="dataFornecedor"
                            :rules="regra.obg_forn"
                            outlined
                            hide-details
                            dense
                            clearable
                            multiple
                            :required="requiredFn"
                        >
                            <template v-slot:selection="{ index }">
                                <span
                                    v-if="index === 0"
                                    class="grey--text caption mr-1"
                                    >{{
                                        filtros.fornecedor.length
                                    }}
                                    selecionados</span
                                >
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col
                        cols="6"
                        sm="6"
                        md="2"
                        lg="2"
                        xl="2"
                        v-if="!ckHideFiltro('ano')"
                    >
                        <v-select
                            label="Ano"
                            v-model="filtros.ano"
                            class="input-cinza"
                            :items="dataAno"
                            :rules="regra.obg_ano"
                            outlined
                            hide-details
                            dense
                            clearable
                            :required="requiredAno"
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="6" sm="6" md="2" lg="2" xl="2" class="px-2">
                        <v-btn
                            class="w-100 new-button"
                            color="primary"
                            @click="submitForm('filtrar')"
                        >
                            <v-icon left>mdi-filter</v-icon> Filtrar
                        </v-btn>
                    </v-col>
                    <!-- <v-col cols="2" md="2" lg="2" xl="2" class="px-2">
						<v-btn
							class="white--text"
							color="primary"
							@click="submitForm('importar')"
						>
							<v-icon left>mdi-upload</v-icon> IMPORTAR
						</v-btn>
					</v-col> -->
                    <v-col cols="2" md="3" lg="3" xl="3">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    class="white--text new-button w-100"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    Importar / Exportar
                                    <v-icon right dark> mdi-menu-down </v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    link
                                    @click="submitForm('exportar')"
                                >
                                    <v-list-item-title
                                        >Exportar</v-list-item-title
                                    >
                                </v-list-item>
                                <v-list-item
                                    v-if="!ckHideFiltro('importar')"
                                    link
                                    @click="submitForm('importar')"
                                >
                                    <v-list-item-title>
                                        Importar
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-col>

                    <v-col cols="2" md="2" lg="2" xl="2" class="px-2">
                        <v-btn
                            class="white--text new-button w-100"
                            color="orange"
                            @click="submitForm('open')"
                        >
                            <v-icon left>mdi-plus-thick</v-icon> Adicionar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
            <Overlay :overlay="overlay" />
        </div>
    </v-card>
</template>

<script>
import Overlay from "../../simulacao/resumo/_components/Overlay.vue";

export default {
    props: {
        hideFiltro: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        Overlay,
    },
    data() {
        return {
            overlay: false,
            action: null,
            //requiredAdd: false,
            requiredFn: false,
            requiredAno: false,
            dataFornecedor: [],
            filtros: {
                ano: 2023,
                fornecedor: [],
            },
            regra: {
                obg_forn: [
                    (v) =>
                        v.length > 0 ||
                        !this.requiredFn ||
                        "Campo obrigatório.",
                ],
                obg_ano: [
                    (v) => !!v || !this.requiredAno || "Campo obrigatório.",
                ],
            },
        };
    },
    computed: {
        dataAno() {
            return Array.from({ length: 12 }, (_, i) => i + 2022);
        },
    },
    methods: {
        ckHideFiltro(key) {
            return this.hideFiltro.includes(key);
        },
        async getFiltros() {
            this.overlay = true;
            const _responseFornecedor = await this.$http().post(
                "/simulacao/resumo/filtroFornecedores",
                {
                    data: {},
                }
            );

            if ((_responseFornecedor || { status: 500 }).status != 200) {
                this.overlay = false;
                return this.$dialog.notify.warning(
                    "Ops! Falha ao carregar os filtros, verifique a sua internet e tente novamente!",
                    {
                        position: "top-right",
                        timeout: 5000,
                    }
                );
            }

            this.dataFornecedor = _responseFornecedor.data.fornecedor;
            this.overlay = false;

            //console.log(this.filtros);
            this.submitForm("filtrar");

            /* setTimeout(() => {
				this.submitForm("filtrar");
			}, 200); */
        },
        submitForm(action) {
            this.requiredFn = ["open"].includes(action);
            this.requiredAno = ["open", "exportar"].includes(action);

            console.log(this.requiredFn, this.requiredAno);

            const validForm = this.$refs.formFiltro.validate();
            console.log(validForm, action);
            if (!validForm) return;

            //console.log(validForm, action);
            //if (validForm) this.$emit("filtrar", this.filtros);

            switch (action) {
                case "filtrar":
                    this.$emit("filtrar", this.filtros);
                    break;
                case "open":
                    this.$emit("modalOpen", this.filtros);
                    break;
                case "importar":
                    this.$emit("modalImportOpen", true);
                    break;
                case "exportar":
                    this.$emit("exportar", this.filtros);
                    break;
            }
        },
    },

    created() {
        if (!this.ckHideFiltro("fornecedor"))
            this.filtros.ano = 2023;
        this.getFiltros();
    },
};
</script>

<style scoped>
.filtro-borda {
    border-radius: 0 15px 15px 15px !important;
}
</style>
