<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="dataTable"
            :options.sync="options"
            :server-items-length="totalDados"
            :loading="loading"
            :footer-props="{
                'items-per-page-options': [25, 50, 100],
            }"
            class="elevation-0 tabela br-15"
            item-key="id_orientacao"
        >
            <template v-slot:item="{ item }">
                <tr
                    :class="{
                        'teal lighten-5': activeItem == item.id_orientacao,
                        'text-center': true,
                    }"
                >
                    <td>{{ item.ano }}</td>
                    <td>{{ item.titulo }}</td>
                    <td>
                        <v-btn
                            small
                            color="primary"
                            outlined
                            @click="rowClick(item)"
                        >
                            <v-icon left>mdi-eye</v-icon> Visualizar
                        </v-btn>
                    </td>
                    <td>
                        <!-- <v-icon
                            class="mr-2"
                            @click="editItem(item)"
                            color="blue"
                            size="22"
                            v-if="!item.load_action"
                        >
                            mdi-pencil
                        </v-icon> -->
                        <v-icon
                            @click="deleteItem(item)"
                            color="grey"
                            size="30"
                            v-if="!item.load_action && !item.ativo"
                            title="Desativado"
                        >
                            mdi-toggle-switch-off-outline
                        </v-icon>
                        <v-icon
                            @click="deleteItem(item)"
                            color="primary"
                            size="30"
                            v-if="!item.load_action && item.ativo"
                            title="Ativado"
                        >
                            mdi-toggle-switch
                        </v-icon>
                        <v-progress-circular
                            color="grey"
                            indeterminate
                            size="20"
                            v-if="item.load_action"
                        />
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    props: {
        ano: Number,
        updateSuccess: {
            type: Object,
            default: () => null,
        },
    },
    data() {
        return {
            totalDados: 0,
            dataTable: [],
            loading: false,
            activeItem: null,
            options: {
                page: 1,
                itemsPerPage: 25,
                multiSort: true,
                mustSort: false,
                sortBy: ["ano"],
                sortDesc: [false],
            },
            headers: [
                {
                    text: "Ano",
                    value: "ano",
                    align: "center",
                    class: "th-separador",
                    select: false,
                },
                {
                    text: "Títulos",
                    align: "center",
                    value: "titulo",
                    class: "th-separador",
                },

                {
                    text: "Visualizar",
                    value: "visualizar",
                    align: "center",
                    sortable: false,
                    class: "th-separador",
                },
                {
                    text: "Actions",
                    value: "actions",
                    sortable: false,
                    align: "center",
                },
            ],
        };
    },

    methods: {
        async dialogConfirm({ title, text }) {
            const _dialogConfirm = await this.$dialog.confirm({
                text: text,
                title: title,
                actions: [
                    { text: "CANCELAR", color: "red" },
                    { text: "OK", color: "teal" },
                ],

                color: "teal lighten-2",
            });

            const _confirm = await _dialogConfirm.wait();

            return _confirm == "OK";
        },
        async getDataFromApi() {
            this.loading = true;
            const { sortBy, sortDesc, page, itemsPerPage } = this.options;

            const _response = await this.$http().post(
                "/simulacao/orientacoes/select",
                {
                    dt_inicial: this.ano != 0 ? +this.ano + "-01-01" : "",
                    offset: (page - 1) * itemsPerPage,
                    limit: itemsPerPage,
                    total: this.totalDados,
                    sortBy: sortBy,
                    sortDesc: sortDesc,
                }
            );

            if ((_response || { status: 500 }).status != 200) {
                this.$dialog.notify.error(
                    "Falha ao listar as rergas, por favor, tente novamente.",
                    {
                        position: "top-right",
                        timeout: 5000,
                    }
                );
                this.loading = false;
                return null;
            }

            this.dataTable = _response.data.dados;
            this.totalDados = _response.data.total[0].total;
            this.loading = false;
        },
        async deleteItem(item) {
            const _index = this.dataTable.findIndex((p) => p.key == item.key);
            const _msg = item.ativo ? "desativação" : "ativação";

            const _confirm = await this.dialogConfirm({
                text: `Confirma a ${_msg} da regra do ano ${item.ano}?`,
                title: _msg.toUpperCase(),
            });

            if (!_confirm) return;

            item.load_action = true;

            const _response = await this.$http().post(
                "/simulacao/orientacoes/update",
                {
                    dt_inicial: item.ano + "-01-01",
                    ativo: !item.ativo,
                }
            );

            if ((_response || { status: 500 }).status != 200) {
                this.$dialog.notify.error(
                    `Falha na ${_msg} da regra, por favor, tente novamente.`,
                    {
                        position: "top-right",
                        timeout: 5000,
                    }
                );
                item.load_action = false;
                return null;
            }

            item.ativo = !item.ativo;
            item.load_action = false;

            //this.dataTable.splice(_index, 1);
        },
        editItem(item) {
            this.$emit("editItem", item);
        },
        rowClick: function (item) {
            console.log(item);

            this.activeItem = item.id_orientacao == this.activeItem ? null : item.id_orientacao;

            this.$emit("visualizar", this.activeItem != null ? item : {});
        },
    },
    filters: {},
    watch: {
        options: {
            handler() {
                console.log("=>A0:", this.ano);
                if (this.ano != null) this.getDataFromApi();
            },
            deep: true,
        },
        ano: {
            handler() {
                console.log("=>A11:", this.ano);
                if (this.ano != null) this.getDataFromApi();
            },
            deep: true,
        },
    },
};
</script>

<style>
tr.v-data-table__selected {
    background: #6ababa40 !important;
}
</style>
