<template>
    <v-container fluid>
        <v-form ref="form" @submit.prevent="submitForm">
            <v-row>
                <v-col>
                    <v-text-field
                        outlined
                        label="Titulo"
                        v-model="titulo"
                        hide-details
                        class="input-cinza"
						:rules="regras.titulo"

                        dense
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-file-input
                        class="input-cinza"
                        v-model="arquivos"
                        :rules="regras.arquivo"
                        :label="'Selecione um arquivo'"
                        outlined
                        dense
                        requerid
                        :multiple="false"
                        show-size
                        :value="arquivos"
                        @change="changeFile()"
                        accept="image/png, image/jpeg, application/pdf"
                    ></v-file-input>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="10">
                    <v-chip
                        v-for="(item, index) in listArquivos"
                        :key="index"
                        class="ma-2"
                        :close="!!item.name"
                        color="teal"
                        text-color="white"
                        @click:close="removeFile(index)"
                    >
                        {{
                            item.name || item.substr(1 + item.lastIndexOf("/"))
                        }}
                    </v-chip>
                </v-col>
                <v-col cols="2">
                    <v-btn
                        class="ml-2 my-1"
                        color="primary"
                        type="submit"
                        :loading="false"
                        v-if="itemUpdate == null"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                    <v-btn
                        class="ml-2 my-1"
                        color="primary"
                        :disabled="!formValidate"
                        :loading="false"
                        v-if="itemUpdate != null"
                        @click="submitUpdate()"
                    >
                        <v-icon left>mdi-refresh</v-icon> Atualizar
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
        <Overlay :overlay="overlay" />
    </v-container>
</template>

<script>
import Overlay from "../../simulacao/resumo/_components/Overlay.vue";

export default {
    components: { Overlay },
    props: {
        ano: Number,
        itemUpdate: {
            type: Object,
            default: () => null,
        },
    },
    data() {
        return {
            overlay: false,
            arquivos: [],
            formValidate: false,
            limitFile: 1,
            regras: {
                titulo: [(v) => !!v || "O campo titulo é obrigatório"],

                arquivo: [
                    (v) => {
                        return (
                            v.length <= this.limitFile ||
                            "Selecione um arquivo."
                        );
                    },
                    (v) => v.length > 0 || "Selecione um arquivo.",

                    (v) => {
                        let _validate = v.filter(
                            (e) =>
                                ![".PDF", ".JPEG", ".JPG", ".PNG"].includes(
                                    e.name.toUpperCase().substr(-4)
                                )
                        );
                        return (
                            _validate.length == 0 ||
                            "O arquivo deve ser dos tipos PDF/JPG/PNG."
                        );
                    },
                ],
            },
        };
    },
    computed: {
        listArquivos() {
            return this.arquivos.length == 0 && this.itemUpdate != null
                ? this.itemUpdate.arquivos
                : this.arquivos;
        },
    },
    methods: {
        getLocalData() {
            const formData = new FormData();

            let _ano = (this.itemUpdate || {}).ano || this.ano;

            formData.append("dt_inicial", _ano + "-01-01");
            formData.append("dt_final", _ano + "-12-31");
            formData.append("titulo", this.titulo);

            this.arquivos.forEach((f, i) => {
                formData.append("files", f);
            });

            return formData;
        },
        async getRegras() {
            this.overlay = true;

            if (this.itemUpdate != null) {
                return (this.overlay = false);
            }

            const _checa = await this.$http().post(
                "/simulacao/orientacoes/valida",
                {
                    dt_inicial: this.ano + "-01-01",
                }
            );

            if ((_checa || { status: 500 }).status != 200) {
                this.$dialog.notify.error(
                    "Ops! Falha ao validar os dados, por favor, tente novamente.",
                    {
                        position: "top-right",
                        timeout: 5000,
                    }
                );
                this.$emit("modalClose", false);
                this.overlay = false;
                return false;
            }

            // if (_checa.data.length > 0 && _checa.data.includes(this.ano)) {
            // 	this.$dialog.notify.warning(
            // 		"Já possui regra cadastrada para o ano selecionado. ",
            // 		{
            // 			position: "top-right",
            // 			timeout: 5000,
            // 		}
            // 	);
            // 	this.$emit("modalClose", false);
            // 	this.overlay = false;
            // 	return false;
            // }

            this.overlay = false;

            return true;
        },
        async submitForm() {
            const validForm = this.$refs.form.validate();
            console.log("validForm=>", validForm);

            if (!(await this.getRegras()) || !this.formValidate) return;

            this.overlay = true;
            const _headers = {
                "Content-Type": "multipart/form-data",
            };
            const _response = await this.$http().post(
                "/simulacao/orientacoes/insert",
                this.getLocalData()
            );

            console.log("_response", _response);

            if ((_response || { status: 500 }).status != 200) {
                this.$dialog.notify.warning(
                    "Falha ao cadastrar a regra por favor, tente novamente.",
                    {
                        position: "top-right",
                        timeout: 5000,
                    }
                );
                this.$emit("modalClose", false);
                this.overlay = false;
                return null;
            }

            this.overlay = false;
            this.$emit("modalClose", false);
            this.$emit("filtrar", {
                ano: this.ano,
            });
        },
        async submitUpdate() {
            const validForm = this.$refs.form.validate();

            if (!this.formValidate) return;

            this.overlay = true;
            const _response = await this.$http().post(
                "/simulacao/orientacoes/update",
                this.getLocalData()
            );

            console.log("_response", _response);

            if ((_response || { status: 500 }).status != 200) {
                this.$dialog.notify.warning(
                    "Falha ao atualizar a regra por favor, tente novamente.",
                    {
                        position: "top-right",
                        timeout: 5000,
                    }
                );
                this.$emit("modalClose", false);
                this.overlay = false;
                return null;
            }

            this.overlay = false;
            this.itemUpdate.arquivos = _response.data[0].arquivos;

            this.$emit("modalClose", false);
            this.$emit("filtrar", {
                ano: this.ano,
            });
        },
        changeFile() {
            this.formValidate = this.$refs.form.validate();
        },
        removeFile(index) {
            this.arquivos.splice(index, 1);
            this.formValidate = this.$refs.form.validate();
        },
    },
    created() {
        this.getRegras();
    },
};
</script>

<style></style>
