<template>
	<v-card class="br-15" elevation="0">
		<v-app-bar flat color="rgba(0, 0, 0, 0)">
			<v-toolbar-title class="text-h6 grey--text pl-0">
				Arquivos {{ ano }}
			</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn color="grey" icon @click="expanded()" right>
				<v-icon>mdi-fullscreen</v-icon>
			</v-btn>
		</v-app-bar>

		<div style="height:500px">
			<v-carousel
				v-model="index"
				:continuous="false"
				:hide-delimiters="true"
				v-if="!empty"
			>
				<v-carousel-item v-for="(item, i) in arquivosAssinados" :key="i">
					<v-sheet color="white" height="100%" tile>
						<v-layout align-center justify-center row fill-height>
							<v-img
								v-if="typeFile(item) != 'pdf'"
								contain
								:lazy-src="item"
								max-width="80%"
								:src="fileMin(item)"
							></v-img>
							<object
								v-if="typeFile(item) == 'pdf'"
								:data="item"
								type="application/pdf"
								height="100%"
							>
								<embed :src="item" type="application/pdf" />
							</object>
						</v-layout>
					</v-sheet>
				</v-carousel-item>
			</v-carousel>
			<Overlay :overlay="overlay" :error="error" :empty="empty"></Overlay>
		</div>
	</v-card>
</template>

<script>
import Overlay from "../../simulacao/resumo/_components/Overlay.vue";

export default {
	components: {
		Overlay,
	},
	props: {
		ano: Number,
		arquivos: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			overlay: false,
			error: false,
			index: 0,
			fullscreen: false,
		};
	},
	computed: {
		empty() {
			return this.arquivos.length == 0;
		},
		arquivosAssinados(){
			return this.arquivos.map((item) => {
				return this.$arquivoProtegido(item);
			});
		}
	},
	methods: {
		typeFile(file) {
			return file.substr(file.lastIndexOf(".") + 1).toLowerCase();
		},
		fileMin(file) {
			const _ext = file.substr(-4);
			return file.replace(_ext, "_resize" + _ext);
		},
		expanded() {
			const _file = this.arquivos[this.index];

			if (this.typeFile(_file) == "pdf") {
				/* return window.open(
					"https://docs.google.com/viewerng/viewer?url=" + _file,
					"_blank"
				); */
				return window.open(					this.$arquivoProtegido(_file) , "_blank");
			}

			const _file_window = window.open("", "_blank");
			_file_window.document.write(
				`
				<html>
					<head>
					</head>
					<body>
					 	<img src="` +
					this.$arquivoProtegido(_file) +
					`" height="100%" width="100%">
					</body>
				</html>
			`
			);
		},
	},
	created() {},
	/* watch: {
		$props: {
			handler() {
				if (this.ano != null) this.getDados();
			},
			deep: true,
			immediate: true,
		},
	}, */
};
</script>
<style scoped>
div[role="button"] {
	display: none !important;
}
</style>
