var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0 tabela br-15",attrs:{"headers":_vm.headers,"items":_vm.dataTable,"options":_vm.options,"server-items-length":_vm.totalDados,"loading":_vm.loading,"footer-props":{
            'items-per-page-options': [25, 50, 100],
        },"item-key":"id_orientacao"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('tr',{class:{
                    'teal lighten-5': _vm.activeItem == item.id_orientacao,
                    'text-center': true,
                }},[_c('td',[_vm._v(_vm._s(item.ano))]),_c('td',[_vm._v(_vm._s(item.titulo))]),_c('td',[_c('v-btn',{attrs:{"small":"","color":"primary","outlined":""},on:{"click":function($event){return _vm.rowClick(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-eye")]),_vm._v(" Visualizar ")],1)],1),_c('td',[(!item.load_action && !item.ativo)?_c('v-icon',{attrs:{"color":"grey","size":"30","title":"Desativado"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-toggle-switch-off-outline ")]):_vm._e(),(!item.load_action && item.ativo)?_c('v-icon',{attrs:{"color":"primary","size":"30","title":"Ativado"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-toggle-switch ")]):_vm._e(),(item.load_action)?_c('v-progress-circular',{attrs:{"color":"grey","indeterminate":"","size":"20"}}):_vm._e()],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }